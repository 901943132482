import React from 'react';
import * as Ra from 'react-admin';

import { ContentTypes } from './contentType';

export const Form = (props:any)=>{
    const record = Ra.useRecordContext();
    console.log(record);
    return (
    <Ra.SimpleForm {...props}>
        <Ra.ImageField source='previewFile.src'/>
        <Ra.FileInput source='file' multiple={false} accept='video/*,image/*' validate={Ra.required()}>
            <Ra.FileField source="src" title="title" target="_blank"/>
        </Ra.FileInput>
        <Ra.TextInput source='name' validate={Ra.required()}/>
        <Ra.ReferenceInput source='advertiserId' reference='advertisers'>
            <Ra.AutocompleteInput optionText='name' validate={Ra.required()}/>
        </Ra.ReferenceInput>
        { record?.contentType == ContentTypes.Image && <Ra.NumberInput source='showDuration' min={1} validate={Ra.required()}/> }
        { record?.contentType == ContentTypes.Video && <Ra.NumberInput source='showDuration' min={1} max={record.videoDuration}/> }
        { record?.contentType == ContentTypes.Video && <Ra.NumberInput source='showStartOffset' min={0} max={record.videoDuration}/> }

        <Ra.DateInput source='fromDate'/>
        <Ra.DateInput source='toDate'/>
    </Ra.SimpleForm>
    )
}