import React from 'react';
import * as Ra from 'react-admin';
import { LocationInput } from '../../components/LocationInput';
import { ProblemCodesField } from '../../components/ProblemCodesField';
import { ScreenshotButton } from '../../components/Screenshot';

export const Form = (props:any)=>(
    <Ra.TabbedForm {...props}>
        <Ra.FormTab label='Состояние'>
            <Ra.Labeled>
                <Ra.TextField source='unique'/>
            </Ra.Labeled>
            <Ra.Labeled>
                <Ra.NumberField source='number'/>
            </Ra.Labeled>
            <Ra.Labeled>
                <Ra.TextField source='state.ip'/>
            </Ra.Labeled>
            <Ra.Labeled>
                <Ra.TextField source='state.hostName'/>
            </Ra.Labeled>
            <Ra.Labeled>
                <Ra.TextField source='state.softVersion'/>
            </Ra.Labeled>
            <Ra.Labeled>
                <Ra.DateField source='state.lastExchange' showTime/>
            </Ra.Labeled>
            <Ra.Labeled>
                <ProblemCodesField source='state.problemCodes'/>
            </Ra.Labeled>
            <Ra.Labeled>
                <ScreenshotButton label='Экран'/>
            </Ra.Labeled>
        </Ra.FormTab>
        <Ra.FormTab label='Данные'>
            <Ra.TextInput source='name' validate={Ra.required()}/>
            <Ra.TextInput source='comment' multiline/>
            <Ra.TextInput source='address' multiline/>
            <LocationInput source='geoPoint'/>
            <Ra.ReferenceInput source='renterId' reference='renters' validate={Ra.required()}>
                <Ra.AutocompleteInput optionText='name' validate={Ra.required()}/>
            </Ra.ReferenceInput>
            <Ra.ReferenceArrayInput source='groupIds' reference='terminalGroups'>
                <Ra.AutocompleteArrayInput optionText='name'/>
            </Ra.ReferenceArrayInput>
        </Ra.FormTab>
        <Ra.FormTab label='Настройки'>
            <Ra.NumberInput source='settings.port' min={1}/>
            <Ra.TimeInput source='settings.startTimeOfPumping'/>
            <Ra.NumberInput source='settings.pumpingTime' min={1}/>
        </Ra.FormTab>
    </Ra.TabbedForm>
)