import { PermMedia } from '@mui/icons-material';

import { List } from "./List";
import { Edit } from "./Edit";
import { Create } from "./Create";

export default {
    list: List,
    edit: Edit,
    create: Create,
    icon: PermMedia
}