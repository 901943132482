export enum PlaylistStates{
    Approved = 0,
    Wait = 1
}

export const PlaylistStateChoises = [
    {id: PlaylistStates.Approved, name: PlaylistStatesToString(PlaylistStates.Approved)},
    {id: PlaylistStates.Wait, name: PlaylistStatesToString(PlaylistStates.Wait)},
]

export function PlaylistStatesToString(ct:PlaylistStates):string{
    return ct==PlaylistStates.Approved ? "Одобрено" : "Ожидает одобрения";
}