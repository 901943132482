import React from 'react';
import * as Ra from 'react-admin';

export const Form = (props:any)=>(
    <Ra.SimpleForm {...props}>
        <Ra.TextInput source='name' validate={Ra.required()}/>
        <Ra.ReferenceArrayInput source='terminalIds' reference='terminals'>
            <Ra.AutocompleteArrayInput optionText='name'/>
        </Ra.ReferenceArrayInput>
    </Ra.SimpleForm>
)