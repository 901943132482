// @ts-ignore
import russianMessages from 'ra-language-russian';

export default {
    ...russianMessages,
    ra:{
        ...russianMessages.ra,
        auth:{
            ...russianMessages.ra.auth,
            username: "Почта"
        },
        action:{
            ...russianMessages.ra.action,
            create: 'Добавить'
        },
        page: {
            ...russianMessages.ra.page,
            create: "Добавить %{name}"
        }
    },
    resources:{
        users:{
            name: "Пользователь |||| Пользователи |||| Пользователей",
            fields:{
                id: "Идентификатор",
                displayName: "Имя",
                email: "Email",
                password: "Пароль",
                userRoleId: "Роль",
                advertiserIds: "Рекламодатели",
                renterIds: "Арендаторы",
                comment: "Заметка"
            }
        },
        userRoles:{
            name: "Роль пользователя |||| Роли пользователей |||| Роли пользователей",
            fields:{
                id:"Идентификатор",
                name:"Название",
                usersCount: "Количество пользователей",
                permissions: "Разрешено"
            }
        },
        advertisers:{
            name: "Рекламодатель |||| Рекламодатели |||| Рекламодателей",
            fields:{
                id:"Идентификатор",
                name:"Название",
            }
        },
        renters:{
            name: "Арендатор |||| Арендаторы |||| Арендаторов",
            fields:{
                id:"Идентификатор",
                name:"Название",
            }
        },
        terminals:{
            name: "Санитайзер |||| Санитайзеры |||| Санитайзеров",
            fields:{
                authCode: "Код авторизации",
                id:"Идентификатор",
                unique: "Уникальный код",
                name:"Название",
                number:"№",
                comment:"Заметка",
                address:"Адрес",
                geoPoint:"Координаты",
                renterId:"Арендатор",
                groupIds:"Группы",
                "settings.port":"Номер порта контроллера",
                "settings.startTimeOfPumping":"Время начала прокачки",
                "settings.pumpingTime":"Время прокачки(сек)",
                "state.ip":"IP адрес",
                "state.hostName":"Имя хоста",
                "state.softVersion":"Версия ПО",
                "state.lastExchange":"Время последнего обмена",
                "state.problemCodes":"Проблемы"
            }
        },
        terminalGroups:{
            name: "Группа санитайзеров |||| Группы санитайзеров |||| Групп санитайзеров",
            fields:{
                id:"Идентификатор",
                name:"Название",
                number:"№",
                terminalIds:"Терминалы"
            }
        },
        contents:{
            name: "Рекламный материал |||| Рекламные материалы |||| Рекламных материалов",
            fields:{
                id:"Идентификатор",
                name:"Название",
                "previewFile.src":"Предпросмотр",
                file: "Файл",
                contentType: "Тип",
                duration: "Продолжительность",
                advertiserId: "Рекламодатель",
                fromDate: "Показывать с даты",
                toDate: "Показывать до даты(включительно)",
                showDuration: "Время показа(сек)",
                showStartOffset: "Пропускать секунд с начала файла(не указано - играть с начала файла)",
                created: "Добавлен"
            }
        },
        playlists:{
            name: "Рекламная компания |||| Рекламные компании |||| Рекламной компании",
            fields:{
                id:"Идентификатор",
                name:"Название",
                state: "Состояние",
                fromDate: "Показывать с даты",
                toDate: "Показывать до даты(включительно)",
                fromTime: "Показывать с времени",
                toTime: "Показывать до времени",
                created: "Добавлен",
                targetIds: "Назначен санитайзерам"
            }
        },
        terminalPlaylists:{
            name: "Сейчас в ротации |||| Сейчас в ротации |||| Сейчас в ротации",
            fields:{
                id:"Идентификатор",
                name:"Название",
                groupIds: "Группы",
                renterId: "Арендатор",
                playlistIds: "Рекламные компании"
            }
        },
        terminalsStates: {
            name: "Журнал изменений |||| Журнал изменений |||| изменений",
            fields:{
                id:"Санитайзер",
                name: "Санитайзер",
                renterId:"Арендатор",
                "state.softVersion":"Версия ПО",
                "state.lastExchange":"Время последнего обмена",
                "state.problemCodes":"Проблемы",
                "syncState.lastSync":"Время последней синхронизации",
            }
        },
        terminalUpdates:{
            name: "Версия ПО |||| Версий ПО |||| Версии ПО",
            fields:{
                id:"Идентификатор",
                file: "Файл обновления",
                state: "Состояние",
                created: "Добавлен",
                version: "Версия",
                description: "Описание",
            }
        },
        history: {
            name: "Журнал |||| Журнал |||| Журнала",
            fields:{
                id:"Идентификатор",
                dateTime: "Дата и время",
                authorId: "Пользователь",
                authorRoleId: "Роль",
                action: "Действие",
                description: "Описание",
            }
        }
    }
};
