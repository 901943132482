import React from "react";
import * as Ra from 'react-admin';
import { Form } from "./Form";

export const Edit = (props:any)=>(
    <Ra.Edit {...props} mutationMode='pessimistic'>
        <Ra.SimpleForm>
            <Ra.TextInput source="version" disabled/>
        </Ra.SimpleForm>
    </Ra.Edit>
)