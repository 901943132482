import React from "react";
import * as Ra from 'react-admin';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="renters" reference="renters" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Арендаторы"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="groups" reference="terminalGroups" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Группы"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="playlists" reference="playlists" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Рекламные компании"/>
        </Ra.ReferenceArrayInput>
    </Ra.Filter>
)

export const List = (props:any)=>(
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'name', order: 'ASC'}}>
        <Ra.Datagrid rowClick={false} bulkActionButtons={false}>
            <Ra.TextField source="name" sortable/>
            <Ra.ReferenceArrayField source="groupIds" reference="terminalGroups" sortable={false}>
                <Ra.SingleFieldList>
                    <Ra.ChipField source="name"/>
                </Ra.SingleFieldList>
            </Ra.ReferenceArrayField>
            <Ra.ReferenceField source="renterId" reference="renters" sortable>
                <Ra.TextField source="name"/>
            </Ra.ReferenceField>
            <Ra.ReferenceArrayField source="playlistIds" reference="playlists" sortable={false}>
                <Ra.SingleFieldList>
                    <Ra.ChipField source="name"/>
                </Ra.SingleFieldList>
            </Ra.ReferenceArrayField>
        </Ra.Datagrid>
    </Ra.List>
)