export enum ContentTypes {
    Image = 0,
    Video = 1
}

export const ContentTypeChoises = [
    {id: ContentTypes.Image, name: ContentTypeToString(ContentTypes.Image)},
    {id: ContentTypes.Video, name: ContentTypeToString(ContentTypes.Video)},
]

export function ContentTypeToString(ct:ContentTypes):string{
    return ct==ContentTypes.Image ? "Изображение" : "Видео";
}