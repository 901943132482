import jsonServerProvider from 'ra-data-json-server';
import reactAdmin ,{ fetchUtils } from 'react-admin';
import { config } from './config';

const httpClient = (url:any, options: fetchUtils.Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const user = localStorage.getItem('user');
    if(user){
        const { token } = JSON.parse(user);
        options.user = {
            authenticated: true,
            token: `Bearer ${token}`
        };
    }
    return fetchUtils.fetchJson(url, options);
};

export const DataProvider: reactAdmin.DataProvider = jsonServerProvider(config.apiUrl, httpClient);