import React, { useCallback } from "react";
import * as Ra from 'react-admin';
import { Card } from '@mui/material';
import { BrowserUpdated } from '@mui/icons-material';

import { config } from "../../config";
import axios from "axios";
import { startDownload } from "../../utils/startDownload";

const Toolbar = (props:any)=>(
    <Ra.Toolbar {...props}>
        <Ra.SaveButton label="Запросить" icon={<BrowserUpdated/>}/>
    </Ra.Toolbar>
)

export const OfflineUpdates = (props:any)=>{
    const onSubmit = useCallback(async (data:any)=>{
        //console.log('report',data);
        try{
            const user = localStorage.getItem('user');
            if(user){
                const { token } = JSON.parse(user);
                var response = await axios.post(`${config.apiUrl}/terminals/offlineupdate`, data, { 
                    headers:{
                        'Authorization': `Bearer ${token}`
                    }
                });
                //console.log('response',response);
                if(response?.data){
                    const url = `${config.apiUrl}/files/${response.data.id}`;
                    startDownload(url, data.name);
                }
            }
        }
        catch(error){

        }
        finally{
        }
    },[]);
    return (
        <Card>
            <Ra.Title title='Оффлайн обновления'/>
            <Ra.SimpleForm toolbar={<Toolbar/>} onSubmit={onSubmit}>
                <Ra.ReferenceArrayInput source="targetIds" reference='playlistTargets' fullWidth>
                    <Ra.AutocompleteArrayInput label="Санитайзеры и группы" optionText={(record:any)=>`${record.name}(${record.type==0?'Санитайзер':'Группа'})`} fullWidth/>
                </Ra.ReferenceArrayInput>
            </Ra.SimpleForm>
        </Card>
    )
}