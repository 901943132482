import React from "react";
import * as Ra from 'react-admin';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Ra.Filter>
)

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("SoftwareVersionEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'created', order: 'DESC'}} hasCreate={hasEdit} hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.TextField source="version" sortable/>
            <Ra.TextField source="created" sortable/>
            <Ra.TextField source="state" sortable/>
            <Ra.TextField source="description" sortable/>
        </Ra.Datagrid>
    </Ra.List>
    )
}