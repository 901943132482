import React, { useCallback, useEffect, useState } from "react";
import * as Ra from "react-admin";
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Box } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { config } from "../../config";
import axios from "axios";


export const ScreenshotButton = (props:any)=>{
    const record = Ra.useRecordContext(props);
    console.log(record)
    const terminalId = record?.id;
    const problemCodes = record?.state?.problemCodes;
    const isOffline = terminalId && (!problemCodes || problemCodes.includes(1));

    const [shown, setShown] = useState(false);
    const onClose = useCallback(()=> setShown(false),[setShown]);
    const onOpen = useCallback(()=> setShown(true),[setShown]);

    return (
        <>
            <ScreenshotDialog shown={shown} onClose={onClose} isOffline={isOffline} terminalId={terminalId}/>
            <Ra.Button label="Посмотреть" disabled={isOffline} onClick={onOpen}/>
        </>
    );
}

interface IDialogState{
    loading: boolean,
    url?: string
}

export const ScreenshotDialog = ({shown, onClose, isOffline, terminalId,...props}:any)=>{
    const [state, setState] = useState<IDialogState>({loading : false, url: undefined});
    const onRefresh = useCallback(async ()=>{
        setState(s=>({...s, loading: true}));
        try{
            const user = localStorage.getItem('user');
            if(user){
                const { token } = JSON.parse(user);
                var response = await axios.get(`${config.apiUrl}/terminals/${terminalId}/screenshot`, { 
                    headers:{
                        'Authorization': `Bearer ${token}`
                    },
                });
                setState(s=>({...s, url: `${config.apiUrl}/files/${response.data}`}))
                
                console.log('response',response);
            }
        }finally{
            setState(s=>({...s, loading: false}));
        }
    },[setState, terminalId]);

    useEffect(()=>{
        if(shown) {
            setState({loading: false, url: undefined});
            onRefresh();
        }
    },[shown])

    return (
        <Dialog open={shown} maxWidth='lg'>
            <DialogTitle>Скриншот</DialogTitle>
            <DialogContent style={{minHeight: 90, height:'75vh', overflow:'hidden'}}>
                { state.url && <img src={state.url} style={{ height:'99%', width:'99%', objectFit:'contain'}}/>}
                { state.loading && 
                    <Box sx={{position:'absolute', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CircularProgress size={90}/>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Ra.Button label='Обновить' size='large' disabled={state.loading || isOffline} onClick={onRefresh}>
                    <Refresh/>
                </Ra.Button>
                <Ra.Button label='Закрыть' size='large' onClick={onClose}/>
            </DialogActions>
        </Dialog>
    )
}