import React, { useCallback } from "react";
import * as Ra from 'react-admin';

export const Settings = (props:any)=>{
    return (
        <Ra.Edit resource="settings" id="1" mutationMode="pessimistic" title="Настройки" {...props}>
            <Ra.TabbedForm>
                <Ra.FormTab label="SMTP">
                    <Ra.TextInput source="smtp.server" label="Сервер" validate={Ra.required()} />
                    <Ra.NumberInput source="smtp.port" label="Порт" validate={Ra.required()} />
                    <Ra.BooleanInput source="smtp.ssl" label="SSL" validate={Ra.required()} />
                    <Ra.TextInput source="smtp.login" label="Логин" validate={Ra.required()} />
                    <Ra.PasswordInput source="smtp.password" label="Пароль" validate={Ra.required()} />
                    <Ra.TextInput source="smtp.senderEmail" label="Email отправителя" />
                </Ra.FormTab>
            </Ra.TabbedForm>
        </Ra.Edit>
    )
}