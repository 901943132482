import { PeopleOutline } from '@mui/icons-material';

import { Create } from './Create';
import { Edit } from './Edit';
import { List } from "./List";

export default {
    list: List,
    create: Create,
    edit: Edit,
    icon: PeopleOutline
}