import React from 'react';
import { Admin, Resource, defaultTheme, CustomRoutes } from 'react-admin';
import { Route } from "react-router-dom";
import polyglotI18nProvider from 'ra-i18n-polyglot';

import russian from './i18n/ru';

import { DataProvider } from './DataProvider';
import { AuthProvider } from './AuthProvider';

import { Layout } from './components/Layout';
import users from './pages/users';
import userRoles from './pages/userRoles';
import advertisers from './pages/advertisers';
import renters from './pages/renters';
import terminals from './pages/terminals';
import terminalGroups from './pages/terminalGroups'
import contents from './pages/contents';
import playlists from './pages/playlists';
import { Monitoring } from './pages/monitoring';
import terminalUpdates from './pages/terminalUpdates'
import history from './pages/history'
import terminalPlaylists from './pages/terminalPlaylists'
import { OfflineUpdates } from './pages/offlineUpdates'
import { Reports } from './pages/reports'
import { Settings } from './pages/settings';

const i18nProvider = polyglotI18nProvider(()=>russian, 'ru');

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiFormControl:{
      styleOverrides:{
        root: {
          minWidth: '250px'
        }
      }
    },
    MuiAutocomplete:{
      styleOverrides:{
        root: {
          minWidth: '250px'
        }
      }
    },
    MuiInputBase:{
      styleOverrides:{
        root: {
          minWidth: '250px'
        }
      }
    },
    /*RaLoginForm:{
      styleOverrides:{
        root: {
          minWidth: '300px'
        },
        content:{
          minWidth: '400px'
        }
      }
    }*/
  }
}

function App() {
  return (
    <Admin
      authProvider={AuthProvider}
      dataProvider={DataProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
      theme={theme}>

        <Resource name='users' {...users}/>
        <Resource name='userRoles' {...userRoles}/>
        <Resource name='advertisers' {...advertisers}/>
        <Resource name='renters' {...renters}/>
        <Resource name='terminals' {...terminals}/>
        <Resource name='terminalGroups' {...terminalGroups}/>
        <Resource name='contents' {...contents}/>
        <Resource name='playlists' {...playlists}/>
        <Resource name='terminalUpdates' {...terminalUpdates}/>
        <Resource name='terminalPlaylists' {...terminalPlaylists}/>
        <Resource name='history' {...history}/>

        <Resource name='playlistTargets'/>
        <Resource name='terminalsStates'/>
        <Resource name='problemCodes'/>
        <CustomRoutes >
          <Route path='/monitoring' element={<Monitoring/>}/>
          <Route path='/offlineUpdate' element={<OfflineUpdates/>}/>
          <Route path='/reports' element={<Reports/>}/>
          <Route path='/settings' element={<Settings/>}/>
        </CustomRoutes>
    </Admin>
  );
}

export default App;
