import React from "react";
import * as Ra from 'react-admin';

export const levelToColor = [
    'info',
    'warning',
    'error'
]

export const ProblemChip = (props:any)=>{
    const record = Ra.useRecordContext();
    return <Ra.ChipField {...props} color={levelToColor[record.level]}/>
}

export const ProblemCodesField = (props:any)=>(
    <Ra.ReferenceArrayField {...props} reference="problemCodes" >
        <Ra.SingleFieldList linkType={false}>
            <ProblemChip source="name"/>
        </Ra.SingleFieldList>
    </Ra.ReferenceArrayField>
)