import React from "react";
import * as Ra from 'react-admin';
import useFileTransform from "../../utils/useFileTransform";
import { Form } from "./Form";

export const Create = (props:any)=>(
    <Ra.Create {...props} transform={useFileTransform()}>
        <Ra.SimpleForm>
            <Ra.FileInput source="file" multiple={false} accept='application/zip' validate={Ra.required()}>
                <Ra.FileField source="src" title="title" target="_blank"/>
            </Ra.FileInput>
        </Ra.SimpleForm>
    </Ra.Create>
)