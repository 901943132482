import React from 'react';
import { useNotify} from 'react-admin';
import axios from 'axios';
import { config } from '../config';

async function processProperty(property, notify){
    if(property && property.rawFile){
        const fd = new FormData();
        fd.append("file", property.rawFile);
        const user = localStorage.getItem('user');
        if(!user){
            throw new Error('Not authorized');
        }
        const { token } = JSON.parse(user);
        const response = await axios.post(`${config.apiUrl}/files/upload`, fd, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log('response',response);
        if(response.status!=200){
            console.log("Upload error. "+response.statusText);
            notify("Upload error. "+response.statusText);
            return null;
        }
        property.fileId = response.data.id;
        property.rawFile = undefined;
    }
}

async function processObj(property, notify){
    if(typeof(property)!=="object") return;
    await processProperty(property);
    for(const k in property){
        await processObj(property[k], notify);
    }
}

export default function useFileTransform(){
    const notify= useNotify();
    return async (data)=>{
        console.log('transform ', data);
        await processObj(data, notify);
        return data;
    }
}