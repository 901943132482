import React from "react";
import * as Ra from 'react-admin';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
    </Ra.Filter>
)

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("RentersEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'name', order: 'ASC'}} hasCreate={hasEdit} hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.TextField source="name" sortable/>
        </Ra.Datagrid>
    </Ra.List>
    )
}