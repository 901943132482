import React from "react";
import * as Ra from 'react-admin';
import { Box, Typography } from '@mui/material';
import { ContentTypeChoises ,ContentTypeToString } from "./contentType";

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="advertisers" reference="advertisers" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Рекламодатели"/>
        </Ra.ReferenceArrayInput>
        <Ra.SelectInput source="contentType" choices={ContentTypeChoises} alwaysOn/>
        <Ra.DateInput source="addFrom" label="Добавлен с даты" alwaysOn/>
        <Ra.DateInput source="addTo" label="Добавлен до даты" alwaysOn/>
    </Ra.Filter>
)

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("ContentsEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'number', order: 'ASC'}}
        hasCreate={hasEdit} 
        hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.ImageField source="previewFile.src" sortable={false}/>
            <Ra.TextField source="name" sortable/>
            <Ra.FunctionField source="contentType" render={(record:any)=>ContentTypeToString(record.contentType)} sortable/>
            <Ra.FunctionField source="duration" render={(record:any)=>`${record.showDuration || record.videoDuration} сек`} sortable={false}/>
            <Ra.ReferenceField source="advertiserId" reference="advertisers" sortable={false}>
                <Ra.TextField source="name"/>
            </Ra.ReferenceField>
            <Ra.DateField source="created" showTime sortable/>
        </Ra.Datagrid>
    </Ra.List>
    )
}