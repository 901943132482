import React from "react";
import * as Ra from 'react-admin';
import { useRecordContext } from "react-admin";
import { PlaylistStateChoises, PlaylistStatesToString } from "./playlistStates";


const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="advertisers" reference="advertisers" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Рекламодатели"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="terminals" reference="terminals" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Терминалы"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="terminalGroups" reference="terminalGroups" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Группы терминалов"/>
        </Ra.ReferenceArrayInput>
        <Ra.SelectInput source="states" label="Состояние" choices={PlaylistStateChoises} alwaysOn/>
        <Ra.DateInput source="addFrom" label="Добавлен с даты" alwaysOn/>
        <Ra.DateInput source="addTo" label="Добавлен до даты" alwaysOn/>
        <Ra.DateInput source="showFrom" label="В ротации с даты" alwaysOn/>
        <Ra.DateInput source="showTo" label="В ротации до даты" alwaysOn/>
    </Ra.Filter>
)

const TargetChip = (props:any)=>{
    const record = useRecordContext();
    return <Ra.ChipField {...props} color={record.type==0 ? 'info': 'warning'}/>
}

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("PlaylistsEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'name', order: 'ASC'}} hasCreate={hasEdit} hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.TextField source="name" sortable/>
            <Ra.FunctionField source="state" render={(record:any)=>PlaylistStatesToString(record.state)} sortable/>
            <Ra.DateField source="fromDate" sortable/>
            <Ra.DateField source="toDate" sortable/>
            {/*<Ra.DateField source="fromTime"/>
            <Ra.DateField source="toTime"/>*/}
            {/*<Ra.FunctionField source="duration" render={(record:any)=>`${record.showDuration || record.videoDuration} сек`}/>*/}
            <Ra.ReferenceArrayField source="targetIds" reference="playlistTargets" sortable={false}>
                <Ra.SingleFieldList>
                    <TargetChip source="name" />
                </Ra.SingleFieldList>
            </Ra.ReferenceArrayField>
        </Ra.Datagrid>
    </Ra.List>
    )
}