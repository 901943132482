import * as Ra from 'react-admin';
import { Troubleshoot, BrowserUpdated, Summarize, Settings } from '@mui/icons-material';


export const Menu = (props:any) => {
    const { permissions } = Ra.usePermissions();
    return (
        <Ra.Menu>
            { permissions && permissions.includes("TerminalStatesShow") && <Ra.Menu.Item to='/monitoring' primaryText="Мониторинг" leftIcon={<Troubleshoot/>}/> }
            { permissions && permissions.includes("TerminalsShow") && <Ra.Menu.ResourceItem name='terminals'/> }
            { permissions && permissions.includes("TerminalGroupsShow") && <Ra.Menu.ResourceItem name='terminalGroups'/> }
            { permissions && permissions.includes("ContentsShow") && <Ra.Menu.ResourceItem name='contents'/> }
            { permissions && permissions.includes("PlaylistsShow") && <Ra.Menu.ResourceItem name='playlists'/> }
            { permissions && permissions.includes("NowPlayingShow") && <Ra.Menu.ResourceItem name='terminalPlaylists'/> }
            { permissions && permissions.includes("ReportsShow") && <Ra.Menu.Item to='/reports' primaryText="Отчеты" leftIcon={<Summarize/>}/> }
            { permissions && permissions.includes("AdvertisersShow") && <Ra.Menu.ResourceItem name='advertisers'/> }
            { permissions && permissions.includes("RentersShow") && <Ra.Menu.ResourceItem name='renters'/> }
            { permissions && permissions.includes("HistoryShow") && <Ra.Menu.ResourceItem name='history'/> }
            { permissions && permissions.includes("TerminalOfflineUpdates") && <Ra.Menu.Item to='/offlineUpdate' primaryText="Оффлайн обновления" leftIcon={<BrowserUpdated/>}/> }
            { permissions && permissions.includes("SoftwareVersionShow") && <Ra.Menu.ResourceItem name='terminalUpdates'/> }
            { permissions && permissions.includes("UsersShow") && <Ra.Menu.ResourceItem name='users'/> }
            { permissions && permissions.includes("UserRolesShow") && <Ra.Menu.ResourceItem name='userRoles'/> }
            { permissions && permissions.includes("Settings") && <Ra.Menu.Item to='/settings' primaryText="Настройки" leftIcon={<Settings/>}/> }
        </Ra.Menu>
    )
}