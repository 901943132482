import React, { useCallback, useEffect, useRef, useState } from "react";


export const DefaultCenter = { lat: 62.406829, lng: 81.802613 };
export const DefaultZoom = 4;

export const Map = (props:any)=>{
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();

    useEffect(() => {
        if(ref.current && !map){
            setMap(new window.google.maps.Map(ref.current, {
                center: props?.center || DefaultCenter,
                zoom: props?.zoom || DefaultZoom
            }));
        }
    }, [ref, map]);

    const onClick = useCallback((e:any)=>{
        props?.onClick && props.onClick(e.latLng!);
    },[props?.onClick]);

    useEffect(()=>{
        if(map){
            ["click"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );
            map.addListener('click', onClick)
        }
    },[map, onClick])

    return (
    <div ref={ref} id="map" style={props?.style}>
        {React.Children.map(props?.children,(child)=>{
            if(React.isValidElement(child)){
                // @ts-ignore
                return React.cloneElement(child, { map })
            }
        })}
    </div>
    )
}