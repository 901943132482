import React, { useCallback } from "react";
import * as Ra from 'react-admin';
import { Card, CardContent, CardActions} from '@mui/material';
import { Summarize } from '@mui/icons-material';
import { config } from "../../config";
import axios from "axios";
import { startDownload } from "../../utils/startDownload";

const ReportTypes = [
    {id: 1, name: "Тестовый отчет"},
]

const toDateValidation = (value:any, allValues:any) =>{
    console.log('validate', value, allValues);
    return "";
}

const Toolbar = (props:any)=>(
    <Ra.Toolbar {...props}>
        <Ra.SaveButton label="Запросить" icon={<Summarize/>}/>
    </Ra.Toolbar>
)

export const Reports = (props:any)=>{
    const onSubmit = useCallback(async (data:any)=>{
        //console.log('report',data);
        try{
            const user = localStorage.getItem('user');
            if(user){
                const { token } = JSON.parse(user);
                var response = await axios.post(`${config.apiUrl}/reports`, data, { 
                    headers:{
                        'Authorization': `Bearer ${token}`
                    }
                });
                //console.log('response',response);
                if(response?.data){
                    const url = `${config.apiUrl}/files/${response.data.id}`;
                    startDownload(url, data.name);
                }
            }
        }
        catch(error){

        }
        finally{
        }
    },[]);
    return (
        <Card>
            <Ra.Title title='Отчеты'/>
            <Ra.SimpleForm toolbar={<Toolbar/>} onSubmit={onSubmit}>
                    <Ra.SelectInput source="type" label="Отчет" choices={ReportTypes} validate={Ra.required()}/>
                    <Ra.DateInput source="from" label="С даты" validate={Ra.required()}/>
                    <Ra.DateInput source="to" label="До даты" validate={[Ra.required(), toDateValidation]}/>
                    <Ra.ReferenceArrayInput source="targets" reference="playlistTargets" validate={Ra.required()}>
                        <Ra.AutocompleteArrayInput label="Санитайзеры и группы" optionText={(record:any)=>`${record.name}(${record.type==0?'Санитайзер':'Группа'})`} validate={Ra.required()}/>
                    </Ra.ReferenceArrayInput>
                
            </Ra.SimpleForm>
        </Card>
    )
}