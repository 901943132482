import React from "react";
import * as Ra from 'react-admin';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput label="Роли" source="userRoles" reference="userRoles" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Роли"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="renters" reference="renters" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Арендаторы"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="advertisers" reference="advertisers" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Рекламодатели"/>
        </Ra.ReferenceArrayInput>
    </Ra.Filter>
)

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("UsersEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'displayName', order: 'ASC'}} hasCreate={hasEdit} hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.TextField source="displayName" sortable/>
            <Ra.TextField source="email" sortable/>
            <Ra.ReferenceField source="userRoleId" reference="userRoles" sortable>
                <Ra.ChipField source="name"/>
            </Ra.ReferenceField>
            <Ra.ReferenceArrayField source="renterIds" reference="renters">
                <Ra.SingleFieldList>
                    <Ra.ChipField source="name"/>
                </Ra.SingleFieldList>
            </Ra.ReferenceArrayField>

            <Ra.ReferenceArrayField source="advertiserIds" reference="advertisers">
                <Ra.SingleFieldList>
                    <Ra.ChipField source="name"/>
                </Ra.SingleFieldList>
            </Ra.ReferenceArrayField>
        </Ra.Datagrid>
    </Ra.List>
    )
}