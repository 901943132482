import React from "react";
import * as Ra from 'react-admin';
import { Box, Typography } from '@mui/material';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="renters" reference="renters" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Арендаторы"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="groups" reference="terminalGroups" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Группы"/>
        </Ra.ReferenceArrayInput>
    </Ra.Filter>
)

const Empty = (props:any)=>(
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            No products available
        </Typography>
        <Typography variant="body1">
            Create one or import from a file
        </Typography>
        <Ra.CreateButton />
    </Box>
)

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("TerminalsEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'number', order: 'ASC'}} hasCreate={hasEdit} hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.NumberField source="number" sortable/>
            <Ra.TextField source="name" sortable/>
            <Ra.ReferenceArrayField source="groupIds" reference="terminalGroups" sortable={false}>
                <Ra.SingleFieldList>
                    <Ra.ChipField source="name"/>
                </Ra.SingleFieldList>
            </Ra.ReferenceArrayField>
            <Ra.ReferenceField source="renterId" reference="renters" sortable>
                <Ra.TextField source="name"/>
            </Ra.ReferenceField>
        </Ra.Datagrid>
    </Ra.List>
    )
}