import React, { useEffect, useRef, useState } from "react";
import * as Ra from 'react-admin';
import { Card, CardContent, Tab} from '@mui/material';
import { Done, Clear } from '@mui/icons-material';
import { TabContext, TabList, TabPanel  } from '@mui/lab';
import { ProblemCodesField } from '../../components/ProblemCodesField';
import { MapList } from "./MapList";
import { ScreenshotButton } from "../../components/Screenshot";

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceInput label="Проблемы" source="problems" reference="problemCodes" alwaysOn resettable>
            <Ra.SelectInput optionText='name' label="Проблемы"/>
        </Ra.ReferenceInput>
    </Ra.Filter>
)

export const Monitoring = (props:any)=>{

    const [tab,setTab]=useState("list");
    return (
        <Card>
            <Ra.Title title='Мониторинг'/>
            <CardContent>
            
                <TabContext value={tab}>
                    <TabList onChange={(e,v)=>setTab(v)}>
                        <Tab value="list" label="Таблицей"/>
                        <Tab value="map" label="На карте"/>
                    </TabList>
                        <TabPanel value="list">
                        <Ra.List resource="terminalsStates" pagination={<Ra.Pagination/>} perPage={10} empty={false} exporter={false} hasCreate={false} hasEdit={false} filters={<Filter/>} title=" ">
                            <Ra.Datagrid bulkActionButtons={false}>
                                <Ra.ReferenceField source="id" reference="terminals" sortable>
                                    <Ra.TextField source="name"/>
                                </Ra.ReferenceField>
                                <Ra.ReferenceField source="renterId" reference="renters" sortable>
                                    <Ra.TextField source="name"/>
                                </Ra.ReferenceField>
                                <ProblemCodesField source='state.problemCodes'/>
                                <Ra.DateField source="state.lastExchange" showTime/>
                                <Ra.FunctionField label="Синхронизирован" render={(r:any)=> r.syncState.availableContentVersion===r.syncState.contentVersion ? <Done/> : <Clear/> }/>
                                <Ra.DateField source="syncState.lastSync" showTime/>
                                <Ra.TextField source="state.softVersion"/>
                                <Ra.WrapperField label="Экран">
                                    <ScreenshotButton/>
                                </Ra.WrapperField>
                            </Ra.Datagrid>
                        </Ra.List>
                    </TabPanel>
                    <TabPanel value="map">
                        <MapList filters={<Filter/>}/>
                    </TabPanel>
                </TabContext>
            </CardContent>
        </Card>
    )
}