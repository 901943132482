import React from 'react';
import * as Ra from 'react-admin';

export const Form = (props:any)=>{
    const record = Ra.useRecordContext();
    const { identity } = Ra.useGetIdentity();
    return (
    <Ra.SimpleForm {...props} defaultValues={{resetPassword:false}}>
        <Ra.TextInput source='email' disabled={!!record?.id} validate={Ra.required()}/>
        <Ra.TextInput source='displayName' validate={Ra.required()}/>
        <Ra.TextInput source='comment' multiline fullWidth/>
        <Ra.FormDataConsumer>
            {
                ({formData})=>
                    identity && identity.id && formData.id===identity.id &&
                    (<Ra.BooleanInput source="resetPassword" label="Сбросить пароль"/>)
            }
        </Ra.FormDataConsumer>
        <Ra.FormDataConsumer>
            {
                ({formData})=>
                    formData.resetPassword &&
                    (<Ra.PasswordInput source="password" label="Новый пароль" validate={Ra.required()}/>)
            }
            </Ra.FormDataConsumer>
        <Ra.ReferenceInput source='userRoleId' reference='userRoles' validate={Ra.required()}>
            <Ra.AutocompleteInput optionText='name' validate={Ra.required()}/>
        </Ra.ReferenceInput>
        <Ra.ReferenceArrayInput source='renterIds' reference='renters'>
            <Ra.AutocompleteArrayInput optionText='name' />
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source='advertiserIds' reference='advertisers'>
            <Ra.AutocompleteArrayInput optionText='name' />
        </Ra.ReferenceArrayInput>
    </Ra.SimpleForm>
    )
}