import React from 'react';
import * as Ra from 'react-admin';

export const Form = (props:any)=>{
    const record = Ra.useRecordContext();
    const notEditable = record?.isEditable===false;
    return (
    <Ra.SimpleForm {...props}>
        <Ra.TextInput source='name' validate={Ra.required()} disabled={notEditable}/>
        <Ra.ReferenceArrayInput reference="permissions" source="permissions" >
            <Ra.CheckboxGroupInput row={false} label="Разрешено" disabled={notEditable}/>
        </Ra.ReferenceArrayInput>
    </Ra.SimpleForm>
    )
}