import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as Ra from 'react-admin';
import { Map } from "../../components/Map";
import { MapMarker } from "../../components/Map/MapMarker";
import ReactDOMServer from "react-dom/server";
import { Card, Stack, Typography, Chip } from '@mui/material';
import { Done, Clear } from '@mui/icons-material';

export const levelToColor = [
    '#1976d2',
    '#ed6c02',
    '#d32f2f'
];

const TerminalMarker = ({terminal, problems, onClick, ...props}:any)=>{
    const svg = useMemo(()=>({
        path: "M 3.6 3 H -3.6 c -0.44 0 -0.8 0.36 -0.8 0.8 v 4.8 c 0 0.44 0.36 0.8 0.8 0.8 h 2 v 0.8 h 3.2 v -0.8 h 2 c 0.44 0 0.796 -0.36 0.796 -0.8 L 4.4 3.8 c 0 -0.44 -0.36 -0.8 -0.8 -0.8 z m 0 5.6 H -3.6 V 3.8 h 7.2 v 4.8 z M -3.2 7.4 v 0.8 h 0.8 c 0 -0.444 -0.356 -0.8 -0.8 -0.8 z m 0 -1.2 v 0.572 c 0.788 0 1.428 0.64 1.428 1.428 H -1.2 c 0 -1.104 -0.896 -2 -2 -2 z m 0 -1.2 v 0.58 c 1.444 0 2.62 1.172 2.62 2.62 H 0 c 0 -1.768 -1.436 -3.2 -3.2 -3.2 zM -1.547 12 z M 0 0 q 2.906 0 4.945 2.039 t 2.039 4.945 q 0 1.453 -0.727 3.328 t -1.758 3.516 t -2.039 3.07 t -1.711 2.273 l -0.75 0.797 q -0.281 -0.328 -0.75 -0.867 t -1.688 -2.156 t -2.133 -3.141 t -1.664 -3.445 t -0.75 -3.375 q 0 -2.906 2.039 -4.945 t 4.945 -2.039 z",
        fillColor: levelToColor[0],
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(0,20)
      }),[]);

    const click = useCallback((a:any)=>{
        console.log(a);
        onClick && onClick(terminal, a);
    },[onClick]);

    return <MapMarker position={terminal.geoPoint} icon={svg} onClick={click} {...props}/>
}

const InfoWindowContent = ({terminal, problems, ...props}:any)=>{
    console.log('problems',problems)
    const lastSync = terminal.syncState?.lastSync && new  Date(terminal.syncState?.lastSync);
    const lastExchange = terminal.state?.lastExchange && new  Date(terminal.state?.lastExchange);
    return (
        <div>
            <div style={{display:'flex', flexDirection:'column'}}>
                <span style={{textAlign:'center', fontSize:'1rem'}}>{terminal.name}</span>
                <span style={{padding:10}} ><span style={{fontWeight:500}}>Адрес:</span>{terminal.address}</span>
                <table style={{border:'1px solid', borderCollapse:'collapse'}}>
                    <tr>
                        <td style={{border:'1px solid', padding:10}}>
                            <span style={{fontWeight:500}}>Проблемы:</span>
                        </td>
                        <td style={{border:'1px solid', paddingLeft:10, paddingRight:10, paddingTop:5, paddingBottom:5}}>
                            <div style={{display:'flex', flexDirection:'column'}}>
                            {terminal.state?.problemCodes.map((code:any)=>{
                                
                                const problem = problems?.find((p:any)=>p.id==code);
                                console.log('find',code, problem, problems)
                                return <span style={{
                                    paddingLeft:12,
                                    paddingRight:12,
                                    paddingTop:4,
                                    paddingBottom:4,
                                    borderRadius:16,
                                    marginTop:5,
                                    marginBottom:5,
                                    backgroundColor: levelToColor[problem?.level||0],
                                    color:'white',
                                    width: 'fit-content'}}>{problem?.name}</span>
                            })}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border:'1px solid', padding:10}}>
                            <span style={{fontWeight:500}}>Время последнего обмена:</span>
                        </td>
                        <td style={{border:'1px solid', padding:10}}>
                            <span>{lastExchange?.toLocaleDateString()} {lastExchange?.toLocaleTimeString()}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border:'1px solid', padding:10}}>
                            <span style={{fontWeight:500}}>Синхронизирован:</span>
                        </td>
                        <td style={{border:'1px solid', padding:10}}>
                            {terminal.syncState?.contentVersion && terminal.syncState?.availableContentVersion===terminal.syncState?.contentVersion ? <Done/> : <Clear/>}
                        </td>
                    </tr>
                    <tr>
                        <td style={{border:'1px solid', padding:10}}>
                            <span style={{fontWeight:500}}>Время последней синхронизации:</span>
                        </td>
                        <td style={{border:'1px solid', padding:10}}>
                            <span>{lastSync?.toLocaleDateString()} {lastSync?.toLocaleTimeString()}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style={{border:'1px solid', padding:10}}>
                            <span style={{fontWeight:500}}>Версия ПО:</span>
                        </td>
                        <td style={{border:'1px solid', padding:10}}>
                            <span>{terminal.state?.softVersion}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    )
}

const InfoWindow = ({map, terminal, problems,...props}:any)=>{
    const iw = useRef<google.maps.InfoWindow>();

    useEffect(()=>{
        console.log('IW', map, terminal);
        if(map && terminal) {
            iw.current = new google.maps.InfoWindow({ content: ""});
            iw.current.setContent(ReactDOMServer.renderToString(<InfoWindowContent terminal={terminal.terminal} problems={problems}/>))
            iw.current.open({anchor:terminal.marker, map});
        }
        return ()=>{
            if(iw.current) {
                iw.current.close();
            }
        }
    },[map, terminal])

    return null;
}

export const OnMap= ({onTerminal,...props}:any)=>{
    const {data} = Ra.useListContext();
    const [selected, setSelected] = useState<{terminal:any,marker:any}| undefined>();
    const codes = useMemo(()=>{
        return data?.map((terminal)=>terminal.state?.problemCodes)?.filter(c=>!!c)?.reduce((a,c)=>[...a,...c],[]) || [];
    },[data]);
    const {data:problems} = Ra.useGetMany('problemCodes', { ids: codes })

    const onClick = useCallback((terminal:any, marker:any)=> setSelected({terminal, marker}),[]);

    return (
        <Map style={{height: '75vh'}}>
            {data?.filter(t=>!!t.geoPoint)?.map((terminal)=>{
                return <TerminalMarker terminal={terminal} problems={problems} onClick={onClick}/>
            })}
            <InfoWindow terminal={selected} problems={problems}/>
        </Map>
    )
}

const Aside = (props:any)=>(
    <div style={{width:200}}>

    </div>
)

export const MapList = ({filters,...props}:any)=>{
    

    return (
    <Ra.List resource="terminalsStates" storeKey="terminalsOnMap" pagination={false} perPage={100000} empty={false} exporter={false} hasCreate={false} hasEdit={false} filters={filters} title=" ">
        <OnMap/>
    </Ra.List>
    )
}