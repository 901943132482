import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const MapMarker = ({map, position, icon, onClick,...props}:any)=>{
    const marker = useRef<google.maps.Marker>();
    const pos = useMemo(()=> new google.maps.LatLng(position.latitude, position.longitude),[position]);

    useEffect(()=>{
        if(map && pos) {
            marker.current = new google.maps.Marker({position: pos, map, icon});
        }
        return ()=>{
            if(marker.current) {
                marker.current.setMap(null);
            }
        }
    },[map, pos])

    useEffect(()=>{
        if(marker.current){
            google.maps.event.clearListeners(marker.current, 'click')
            if(onClick)
                marker.current.addListener('click',()=> onClick(marker.current))
        }
    },[marker.current, onClick])
    return null;
}