import React, { useCallback, useMemo, useState } from 'react';
import * as Ra from 'react-admin';
import { ContentTypeChoises, ContentTypeToString } from '../contents/contentType';
import { KeyboardArrowUp, KeyboardArrowDown, Delete, Add } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { PlaylistStateChoises, PlaylistStatesToString } from './playlistStates';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="advertisers" reference="advertisers" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Рекламодатели"/>
        </Ra.ReferenceArrayInput>
        <Ra.SelectInput source="contentType" choices={ContentTypeChoises} alwaysOn resettable/>
        <Ra.DateInput source="addFrom" alwaysOn/>
        <Ra.DateInput source="addTo" alwaysOn/>
    </Ra.Filter>
)

const ChooseContentModal = ({onItem,open, onClose,...props}:any)=>{

    /*const onItem = useCallback((id:Ra.Identifier)=>{
        return false;
    },[]);*/
    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' >
            <DialogTitle>Выберите рекламный материал</DialogTitle>
            <DialogContent>
                <Ra.List resource='contents' hasCreate={false} hasEdit={false} hasShow={false} exporter={false} filters={<Filter/>}>
                    <Ra.Datagrid rowClick={onItem} bulkActionButtons={false} >
                        <Ra.ImageField source="previewFile.src"/>
                        <Ra.TextField source="name" sortable/>
                        <Ra.FunctionField source="contentType" render={(record:any)=>ContentTypeToString(record.contentType)}/>
                        <Ra.FunctionField source="duration" render={(record:any)=>`${record.showDuration || record.videoDuration} сек`}/>
                        <Ra.ReferenceField source="advertiserId" reference="advertisers" link={false}>
                            <Ra.TextField source="name"/>
                        </Ra.ReferenceField>
                    </Ra.Datagrid>
                </Ra.List>
            </DialogContent>
            <DialogActions>
                <Ra.Button label='Отмена' size='large' onClick={onClose}/>
            </DialogActions>
        </Dialog>
    )
}


const PlaylistItemsListButton = ({children,onClick,disabled,...props}:any)=>{
    const ctx = Ra.useRecordContext(props);
    console.log('ctx',ctx);
    const click = useCallback((e:any)=>{
        onClick && onClick(ctx);
        e.stopPropagation();
    },[onClick,ctx]);
    return (
        <Ra.Button {...props} onClick={click} disabled={disabled && disabled(ctx)}>
            {children}
        </Ra.Button>
    )
}

const PlaylistItemsList = (props:any)=>{
    const [showAdd, setShowAdd] = useState(false);
    const { field } = Ra.useInput(props);
    console.log('input',field);
    const { data : contents, isLoading, error } = Ra.useGetMany('contents', { ids: field.value} )
    console.log('contents',contents);

    const data = useMemo(()=>{
        if(!contents || contents.length==0) return [];
        return field.value.map((id:string, i:number)=>({ ...(contents.find(v=>v.id===id)), position:i }));
    },[contents, field.value]);
    console.log('data',data);

    const onAdd = useCallback(()=> setShowAdd(true),[setShowAdd]);
    const onCloseAdd = useCallback(()=>setShowAdd(false),[setShowAdd]);
    const onAddItem=useCallback((id:string)=>{
        const n = [...field.value];
        n.push(id);
        field.onChange(n);
        onCloseAdd();
    },[field.onChange, setShowAdd]);
    const onDelete=useCallback((record:any)=>{
        console.log('delete',record);
        const n = [...field.value];
        n.splice(record.position,1);
        field.onChange(n);
    },[field.onChange]);
    const onUp = useCallback((record:any)=>{
        console.log('onUp',record);
        const n = [...field.value];
        n.splice(record.position-1, 0, n.splice(record.position, 1)[0]);
        field.onChange(n);
    },[field.onChange]);
    const onDown = useCallback((record:any)=>{
        console.log('onUp',record);
        const n = [...field.value];
        n.splice(record.position+1, 0, n.splice(record.position, 1)[0]);
        field.onChange(n);
    },[field.onChange]);
    const disableFirst =useMemo(()=>(record:any)=>{ return record.position===0 },[]);
    const disableLast =useMemo(()=>(record:any)=>{ return record.position===data.length-1 },[data]);
    return (
        <>
        <PlaylistItemsListButton label='Добавить материал в плейлист' size='large' onClick={onAdd}>
            <Add/>
        </PlaylistItemsListButton>
        <Ra.Datagrid
            data={data}
            total={data?.length}
            isLoading={isLoading}
            sort={{field:'id', order:'ASC'}}
            bulkActionButtons={false}
            rowClick={false}
            resource='contents'
            >
            <Ra.ImageField source="previewFile.src"/>
            <Ra.TextField source="name" sortable/>
            <Ra.FunctionField source="contentType" render={(record:any)=>ContentTypeToString(record.contentType)}/>
            <Ra.FunctionField source="duration" render={(record:any)=>`${record.showDuration || record.videoDuration} сек`}/>
            <Ra.ReferenceField source="advertiserId" reference="advertisers">
                <Ra.TextField source="name"/>
            </Ra.ReferenceField>
            <PlaylistItemsListButton label='Вверх' disabled={disableFirst} onClick={onUp}>
                <KeyboardArrowUp/>
            </PlaylistItemsListButton>
            <PlaylistItemsListButton label='Вниз' disabled={disableLast} onClick={onDown}>
                <KeyboardArrowDown/>
            </PlaylistItemsListButton>
            <PlaylistItemsListButton label='Удалить' color='error' onClick={onDelete}>
                <Delete/>
            </PlaylistItemsListButton>
        </Ra.Datagrid>
        <ChooseContentModal onItem={onAddItem} open={showAdd} onClose={onCloseAdd}/>
        </>
    );
}

export const Form = (props:any)=>{
    const record = Ra.useRecordContext();
    console.log(record);
    return (
    <Ra.SimpleForm {...props}>
        <Ra.TextInput source='name' validate={Ra.required()}/>
        {record?.state>=0 &&
            <Ra.SelectInput source='state' choices={PlaylistStateChoises}/>
        }

        <Ra.DateInput source='fromDate'/>
        <Ra.DateInput source='toDate'/>

        <Ra.TimeInput source='fromTime'/>
        <Ra.TimeInput source='toTime'/>

        <Ra.ReferenceArrayInput source="targetIds" reference='playlistTargets'>
            <Ra.AutocompleteArrayInput optionText={(record:any)=>`${record.name}(${record.type==0?'Санитайзер':'Группа'})`}/>
        </Ra.ReferenceArrayInput>

        <PlaylistItemsList source='itemIds'/>

    </Ra.SimpleForm>
    )
}