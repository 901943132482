import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Ra from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment, TextField  } from '@mui/material';
import { EditLocationAlt } from '@mui/icons-material';

export interface GeoPoint {
    latitude: number
    longitude: number
}

const Map = (props:any)=>{
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [location,setLocation] = useState<google.maps.LatLng>();
    const [marker, setMarker] = useState<google.maps.Marker>();

    const onSetMarker= useCallback((e:any)=>{
        setLocation(e.latLng!);
        props?.onChange && props.onChange(e.latLng!);
    },[setLocation]);

    useEffect(() => {
        if(ref.current && !map){
            setMap(new window.google.maps.Map(ref.current, {
            center: location || { lat: 62.406829, lng: 81.802613 },
            zoom: 4,
            }));
            if(props?.location)
                setLocation(props?.location)
        }
    }, [ref, map]);

    useEffect(()=>{
        if(map){
            ["click"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );
            map.addListener('click',onSetMarker)
        }
    },[map, onSetMarker])

    useEffect(()=>{
        if(marker) {
            marker.setMap(null);
            setMarker(undefined);
        }
        if(location){
            setMarker(
                new google.maps.Marker({
                    position: location,
                    map,
                    title: "Координаты санитайзера",
                  })
            );
        }
    },[location])

    return <div ref={ref} id="map" style={{height: '75vh', width:'60vw'}} />;
}

export const LocationInput=(props:any)=>{
    const { field } = Ra.useInput(props);
    const translate = Ra.useTranslateLabel();
    const [ location, setLocation ] = useState<GeoPoint>(field.value);
    const [ open, setOpen] = useState<boolean>(false);
    const gl = useMemo(()=> !location ? undefined : new google.maps.LatLng(location.latitude, location.longitude) ,[location]);
    const onChange = useCallback((ll:google.maps.LatLng)=> setLocation({latitude: ll.lat(), longitude: ll.lng()}) ,[setLocation]);
    const onClose=useCallback(()=>setOpen(false),[setOpen]);
    const onSave=useCallback(()=>{
        field.onChange(location);
        onClose();
    } ,[field.onChange, onClose]);
    return (
        <>
            {/*<TextField label={translate(props)} variant="filled" value={location && `${location.latitude}, ${location.longitude}`} onClick={()=>setOpen(true)}  InputProps={{ readOnly: true, endAdornment: <InputAdornment position='end'><EditLocationAlt/></InputAdornment> }}/>*/}
            <Ra.TextInput {...props} onClick={()=>setOpen(true)} format={(v)=>v && `${v.latitude}, ${v.longitude}`} InputProps={{ readOnly: true, endAdornment: <InputAdornment position='end'><EditLocationAlt/></InputAdornment> }}/>
            <Dialog open={open} maxWidth='lg' onClose={onClose}>
                <DialogTitle>Укажите точку на карте</DialogTitle>
                <DialogContent >
                    <Map location={gl} onChange={onChange}/>
                </DialogContent>
                <DialogActions>
                    <Ra.Button label='OK' size='large' disabled={!location} onClick={onSave}/>
                    <Ra.Button label='Отмена' size='large' onClick={onClose}/>
                </DialogActions>
            </Dialog>
        </>
    )
}