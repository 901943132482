import React from "react";
import * as Ra from 'react-admin';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.TextInput label="Действие" source="action" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="authors" reference="users" label="Пользователи" alwaysOn resettable>
            <Ra.AutocompleteArrayInput optionText="displayName" label="Пользователи"/>
        </Ra.ReferenceArrayInput>
        <Ra.ReferenceArrayInput source="roles" reference="userRoles" label="Роли" alwaysOn resettable>
            <Ra.AutocompleteArrayInput optionText="name" label="Роли"/>
        </Ra.ReferenceArrayInput>
        <Ra.DateInput source="from" label="C даты" alwaysOn/>
        <Ra.DateInput source="to" label="До даты" alwaysOn/>
    </Ra.Filter>
)

export const List = (props:any)=>(
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'dateTime', order: 'DESC'}}>
        <Ra.Datagrid rowClick={false} bulkActionButtons={false}>
            <Ra.DateField source="dateTime" showTime sortable/>
            <Ra.ReferenceField source="authorId" reference="users" sortable={false}>
                <Ra.TextField source="email" sortable={false}/>
            </Ra.ReferenceField>
            <Ra.ReferenceField source="authorRoleId" reference="userRoles" sortable={false}>
                <Ra.TextField source="name" sortable={false}/>
            </Ra.ReferenceField>
            <Ra.TextField source="action" sortable={false}/>
            <Ra.TextField source="description" sortable={false}/>

        </Ra.Datagrid>
    </Ra.List>
)