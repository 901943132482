import React from "react";
import * as Ra from 'react-admin';
import { Box, Typography } from '@mui/material';

const Filter = (props:any)=>(
    <Ra.Filter {...props}>
        <Ra.TextInput label="Поиск" source="q" alwaysOn resettable/>
        <Ra.ReferenceArrayInput source="terminals" reference="terminals" allowEmpty={false} alwaysOn resettable>
            <Ra.AutocompleteArrayInput source="name" optionText="name" label="Терминалы"/>
        </Ra.ReferenceArrayInput>
    </Ra.Filter>
)

export const List = (props:any)=>{
    const { permissions } = Ra.usePermissions();
    const hasEdit = permissions && permissions.includes("TerminalGroupsEdit")
    return (
    <Ra.List {...props} exporter={false} filters={<Filter/>} sort={{field: 'number', order: 'ASC'}} hasCreate={hasEdit} hasEdit={hasEdit}>
        <Ra.Datagrid rowClick={hasEdit && "edit"} bulkActionButtons={false}>
            <Ra.NumberField source="number" sortable sx={{'width':'10px'}}/>
            <Ra.TextField source="name" sortable/>
            <Ra.FunctionField label="Терминалов" render={(record:any)=> record.terminalIds.length}/>
        </Ra.Datagrid>
    </Ra.List>
    )
}